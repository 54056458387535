// CalendarPage
.PhotoGalleryPage {
	#header  {
		border:1px solid #efefef;
	}
	#main-content {
		padding-top:70px;
		@include breakpoint(medium) {
			padding-top:100px;
		}
	}
	.page-title {
	    font-weight: 300;
	    border-bottom: 2px solid #efefef;
	    font-size: 35px;
	    margin-bottom: 20px;
	    margin-top: 20px;
		@include breakpoint(medium) {
		    font-size: 45px;
		}
	}
	.tools-col {
		position:relative;
		margin-bottom:30px;
	}
	.btn-upload {
		font-size:15px;
		font-weight:400;
		@include breakpoint(small down) {
			font-size:13px;
			letter-spacing:-1px;
			padding:10px 9px;
		}
	}
/*
	.photo-grid-col {
		@include grid-column(3, 10px);
	}
*/
	.photo-grid-row {
		@extend .small-up-2;
		@extend .medium-up-3;
		@extend .large-up-5;
		margin-right: -5px;
		margin-left: -5px;
		.column {
			padding-left:5px;
			padding-right:5px;
		}
		.details {
			padding:10px 0;
		}
	}
	.photo-stream-thumb {
		display:block;
		background:#efefef;
		padding:8px;
	}
	#category_dropdown {
		position:absolute;
		right:15px;
		top: 0px;
		width: 145px;
		overflow:hidden;
		background:#ffffff;
		@include breakpoint(medium) {
			top:10px;
			top:7px;
			width:170px;
		}
	  ul {
		  border:2px solid $site-gray-light;
		  border-top:none;
	  }
	}
	#category_dropdown #dropdown_arrow {
		height: 38px;
		line-height: 30px;
		padding: 3px 0 0 12px;
		font-size: 13px;
		background:$site-gray;
		color:white;
		@include breakpoint(medium) {
			font-size: 15px;
		}
		.fa {
			position: absolute;
			font-size: 19px;
			right: 10px;
			top: 9px;
		}
	}
	#category_dropdown ul { display:none; }
	#category_dropdown:hover { overflow:visible; }
	#category_dropdown:hover ul {
	  display:block;
	  padding:0;
	  margin:0;
	  list-style:none;
	}
	#category_dropdown:hover ul li { list-style:none; }
	#category_dropdown:hover ul li a {
	  display:block;
	  padding:6px;
	  font:14px/16px 'OpenSansRegular', Arial, sans-serif;
	}
	#category_dropdown:hover ul li a:hover { background:#eeeeee; }

	.paging {
		margin-top:30px;
		text-align:center;
		.number {
			font-family:'Open Sans';
			display:inline-block;
			color:$site-blue;
			padding:6px 12px;
			border:2px solid $site-blue;
			cursor:pointer;
// 			&:hover, &:focus {color:white}
			&.current {
				color:white;
				background:$site-blue;
			}
		}
	}
	
	.fancybox-overlay-fixed {background:rgba(0,0,0,0.8) !important;}

	.pagination {
		margin-top:50px;
	}

}