@charset 'utf-8';

@import 'settings';
@import 'fonts';
@import 'mixins';
@import 'foundation';
@import 'motion-ui';
@import 'slick.scss';
@import url(https://fonts.googleapis.com/css?family=Open+Sans:700,600,400,300);


@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

$trade-gothic: 'TradeGothicLTPro-BdCn20';

$site-gray: #434244;
$site-blue: #093161;
$site-orange:#d59f0e;
$text-gray: $site-gray;
$site-gray-light: #d0d1d3;

a {color:$site-blue; &:hover,&:focus {color:$site-blue;}}

p, ul {
	font-family:'Open Sans';
	@include breakpoint(small only) {
		font-size:14px;
	}
}

h1,h2,h3,h4 {
	font-family:'Open Sans';
	color:$text-gray;
	letter-spacing:-1px;
	font-weight:600;
}
h5 {
	font-family:'Open Sans';
	font-weight:600;
}
form {
    .middleColumn {
        position:relative;
    }
    label.error {
        text-align: right;
        font-style: italic;
        background: red;
        font-weight: 600;
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 12px;
        color: white;
        padding: 1px 8px;
    }
}
.heading-border {
	display:inline-block;
	padding:0 15px 2px;
	margin-bottom:15px;
	border-top:1px solid #D0D1D3;
	border-bottom:1px solid #D0D1D3;
	text-transform:uppercase;
	@include breakpoint(medium) {
		margin-bottom:30px;
		line-height:66px;
		padding:0 15px;
	}
}
.tg-heading {
	font-family:$trade-gothic;
	font-weight:normal;
	font-size:32px;
	letter-spacing:0px;
	text-transform:uppercase;
	text-align:center;
	@include breakpoint(medium) {
		font-size:58px;
	}
}
.short-divider {
	width: 50px;
    border-bottom: 2px solid $site-gray;
}

.btn {
	font-family:'Open Sans';
	font-weight:300;
	display:inline-block;
	color:white;
	padding:12px 25px;
	min-width:120px;
	&:hover, &:focus {color:white}
}
.btn-gray {
	@extend .btn;
	font-weight:600;
	background:$site-gray-light;
	color:$site-gray;
	@include transition(background .2s ease);
	&:hover, &:focus {
		color:$site-gray;
		background:darken($site-gray-light,5);
	}
}
.btn-blue {
	@extend .btn;
	font-weight:600;
	background:$site-blue;
	@include transition(background .2s ease);
	&:hover, &:focus {
		background:darken($site-blue,5);
	}
}
form input[type="submit"] {
	@extend .btn;
	@extend .btn-blue;
	border:none;
}

html, body {
    height: 100%;
	font-family:'Open Sans';
}
// vertically center tile info
.info-center {
	display:table;
	vertical-align:middle;
	width:100%;
	height:100%;
// 	text-align:center;
}
.info-inner {
	display:table-cell;
	vertical-align:middle;
}

.app-wrap {
    position: relative;
    width: 100%;
    overflow-x: hidden;
}

/*
.slick-dots {
	text-align:center;
	margin:5px 0px;
	button {display:none}
	li {
		display:inline-block;
		margin:0 5px;
		&:before {
			content: '';
			width: 9px;
			height: 9px;
			background: #585858;
			display: block;
			@include border-radius(8px);
		}
	}
}
*/

#section-quick-links {
	display:none;
	height:0px;
	overflow:hidden;
	background:$site-gray;
	@include transition(height .3s ease);
	@include breakpoint(medium) {
		display:block;
	}
	&.open {height:85px}
	> .row {
		padding:10px 0px;
	}
	ul {
		margin:0px;
		list-style-type:none;
		li {
			display:inline-block;
			margin-right:12px;
			@media (max-width:870px) {
				margin-right:3px;
			}
		}
		.l1 {
			display: block;
			color: white;
			font-size: 12px;
			width: 124px;
			padding: 7px 0px 5px;
			border-bottom: 1px solid #616161;
			@media (max-width:870px) {
				font-size:11px;
				width: 95px;
			}
			@include breakpoint(large) {
				width:142px;
			}
		}
	}
}
#header {
	position:fixed;
	width:100%;
	z-index:11;
	@include bg-layer(#ffffff,1);
	@include transition(height .3s ease);
	.logo {
		display:block;
		margin:auto;
		width:160px;
		@include breakpoint(medium) {
			margin:inherit;
			width:200px;
		}
		@media (max-width:870px) {
			margin:auto;
		}
	}
	.header-row {
		z-index:10;
		padding:12px 0 15px;
		@include breakpoint(medium) {
			padding:20px 0 23px;
		}
	}
	.columns {position:relative}
	.btn-mobile-menu {
		position: absolute;
		top: -12px;
		right: 0px;
		font-size: 23px;
		height: 70px;
		display: block;
		padding: 18px 15px;
		color:$site-gray;
		@include breakpoint(medium) {
			display:none;
		}
	}
}

#mobile-menu {
	position: fixed;
	top: 70px;
	left: 0px;
	background: $site-gray;
	z-index: 100;
	width: 100%;
	padding:0px;
	margin: 0px;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.2s;
    -moz-transition: max-height 0.2s;
    transition: max-height 0.2s;
	//overflow-y:visible;
	-webkit-overflow-scrolling: touch;
	//@include opacity(.95);
	//@include transition(height .2s ease);
	@include breakpoint(medium) {
		display:none !important;
	}
	&.open {
		//height:0px !important;
    	//overflow:hidden;
        max-height: 500px;
	}
	.btn-mobile-menu {
		position: absolute;
		right: 12px;
		color: $site-blue;
		font-size: 26px;
		top: 5px;
		display:none;
	}
	ul {
		list-style-type:none;
		margin:0px;
		padding:0px;
		text-align:left;
	}
	.l1-item {
		.mobile-menu-expand {
			float:right;
			right: 0px;
			top: 0px;
			padding: 12px 12px 10px;
			font-size:18px;
			color: white;
		}
	}
	.l1 {
		color:white;
		font-size:15px;
		font-weight:400;
		display:block;
		padding:8px 15px;
		border-bottom:1px solid $site-gray-light;
	}
	.children {
		overflow:hidden;
		@include transition(left .3s ease);
		position: absolute;
		width: 100%;
		height:100%;
		z-index: 2;
		top: 0px;
		background:darken($site-gray,5);
		left: 0px;
		padding-top: 41px;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		&.closed {left:100% !important;}
	}
	.mobile-menu-collapse {
	    font-size: 21px;
	    color: white;
	    position: absolute;
	    top: 0px;
	    left: 0px;
	    display: block;
	    height: 41px;
	    padding-top: 3px;
	    padding-right: 12px;
	    width: 100%;
	    text-align: right;
	    background: #2B2B2B;
	    border-bottom: 1px solid #d0d1d3;
	}
	.l2 {
		color: white;
		font-size: 15px;
		padding: 7px 12px 9px;
		display: block;
		background:darken($site-gray,5);
		border-bottom: 1px solid $site-gray-light;
	}
	.secondary-nav-list, .quick-links-list {
		margin:5px 0px 0px;
		padding:0px 0px 0px;
		.l1 {
			font-weight:400;
			padding: 9px 15px;
			font-size: 13px;
			margin-bottom:1px;
		}
	}
	.secondary-nav-list {
		.l1 {
			color:white;
			background:$site-blue;
		}
	}
	.quick-links-link {
		background:$site-blue;
	}
	.quick-links-list {
		.l1 {
			color:black;
			background:$site-gray-light;
		}
	}
}

#main-nav {
	display:none;
	position:absolute;
	right:15px;
	top:8px;
	@include breakpoint(medium) {
		display:block;
	}
	@media (max-width:870px) {
		position:relative;
		top:13px;
		width:100%;
		text-align:center;
		right:0px;
	}
	ul {
		margin:0px;
		padding:0px;
		list-style-type:none;
	}
	.l1-link {
		color:$site-gray;
		padding:20px 14px 38px;
		font-size:16px;
		@media (max-width:1080px) {
			font-size:14px;
			padding:20px 13px 38px;
		}
		@media (max-width:870px) {
			padding:13px 9px 20px;
		}
	}
	.l1-item {
		display:inline-block;
		position:relative;
		&:hover {
			.nav-tray {
				display:block;
			}
			.l1-link {
				background:$site-gray;
				color:white;
			}
		}
		&.last {
			@media (max-width:1080px) {
				display:none;
			}
		}
	}
	.nav-tray {
		display:none;
		position:absolute;
		z-index:20;
		top:60px;
		left:0px;
		background:$site-gray;
		padding:20px 16px;
		width:490px;
		text-align:left;
		@media (max-width:870px) {
			top:42px;
			width:425px;
			padding:20px 10px;
		}
		&.flip {
			left:auto;
			left:inherit;
			right:0px;
		}
		ul {
			display:block;
			float:left;
			width:50%;
			padding-right:8px;
		}
		.col-rt {
			padding-left:8px;
			padding-right:0px;
		}
	}
	.l2-link {
		display:block;
		font-size:16px;
		line-height: 22px;
		padding:7px 12px;
		color:white;
		&:hover {
			background:darken($site-gray, 7);
		}
		@media (max-width:870px) {
			font-size:14px;
			padding:7px 10px;
			line-height:21px;
		}
	}
	.li-quick-link {display:inline-block}
	.btn-quick-link {
		border: 2px solid $site-blue;
		display: inline-block;
		padding: 7px 15px;
		font-size: 16px;
		color: $site-gray;
		font-weight: 600;
		background:transparent;
		@include transition(all .2s ease);
		&:hover, &.active {
			background:$site-blue;
			color:white;
		}
		@media (max-width:1080px) {
			font-size:14px;
			padding-left:12px;
			padding-right:12px;
		}
		@media (max-width:870px) {
			font-size: 13px;
			padding-left: 8px;
			padding-right: 8px;
		}
	}
}
#main-content {
	padding-bottom:30px;
	&.no-banner {
		padding-top:80px;
		@include breakpoint(medium) {
			padding-top:150px;
		}
	}
/*
	padding-top:70px;
	@include breakpoint(medium) {
		padding-top:100px;
	}
	&.np {
		padding-top:0px !important
	}
*/
}
.main-content-col {
	@include breakpoint(large) {
		@include grid-column(10);
		@include grid-column-position(center);
	}
}
.touch .HomePage #bgvid {
	display:none;
}
.HomePage {
	h2 {
		font-family:$trade-gothic;
		font-weight:normal;
		text-transform:uppercase;
	}
	#main-content {padding-top:0px; padding-bottom:0px;}
	.banner-slider-wrap {
		position:relative
	}
	#banner-slider {
		position:relative;
		@include breakpoint(small only) {
			height:320px !important;
		}
	}
	.slick-track, .slick-list {
		height:100%;
	}
	#background-wrap {
		position:relative;
        overflow:hidden;
        max-height:820px;
        @include breakpoint(medium down) {
            height:inherit !important;
        }
	}
	.background-video {
		width: 100%;
		height: 440px;
		overflow: hidden;
		position: relative;
		background-color:#000;
		background-position:center center;
		background-size:cover;
		@include breakpoint(medium) {
			height: 640px;
		}
		@include breakpoint(large) {
			height: 820px;
		}
		&:after {
			content: '';
			background: black;
			position: absolute;
			width: 100%;
			height: 100%;
			@include opacity(.5);
			z-index:5;
		}
        .iframe-video-embed {
            position: absolute;
            border: none;
            min-width: 100vw;
            left: 50%;
            transform: translateX(-50%);
            width: calc((100vh - 112px) * 1.77);
            height: calc(100vh - 112px);
            min-height: 56vw;
            opacity: 0.8;
        }
	}
	.video-embed {
		width: 1000px;
		position: absolute;
		left: 50%;
		margin-left: -500px;
		height: 620px;
		background:transparent;
		@include breakpoint(medium) {
			width: 1500px;
			height: 840px;
			margin-left: -750px;
		}
		@include breakpoint(large) {
			width: 1800px;
			height: 920px;
			margin-left: -900px;
		}
	}
    #bgvid {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        background-size: cover;
        z-index:4;
    }
	.home-banner {
		position:relative;
		height:320px;
		width:100%;
		@include background-size(cover);
		background-position:center top;
		@include breakpoint(medium) {
			height:100%;
		}
	}
	.shade {
		position:absolute;
		width:100%;
		height:100%;
		left:0px;
		top:0px;
		background:black;
	}
	.heading, .video-heading {
		position: absolute;
		width: 100%;
		text-align: center;
		top: 50%;
		z-index: 10;
		color: white;
		font-size: 40px;
		line-height:48px;
		margin-top: -10px;
		@include breakpoint(medium) {
			font-size: 65px;
			line-height:72px;
			margin-top: -60px;
		}
	}
	.btn-wrap {
		position:absolute;
		width:100%;
		bottom:5px;
		text-align:center;
		z-index:5;
		padding:0 9px;
		@include breakpoint(medium) {
			bottom:140px;
			padding:0px;
		}
		@include breakpoint(large) {
			bottom:200px;
			padding:0px;
		}
		.btn {
			position:relative;
			background:#636466;
			@include opacity(.9);
			@include transition(all .3s ease);
			&:hover {
				@include opacity(1);
			}
			@include breakpoint(small only) {
				width: 50%;
				font-size:18px;
				padding:8px;
			}
			@include breakpoint(medium) {
				width:200px;
				margin:0 10px;
				font-size:21px;
			}
			&.btn-left {
				@include breakpoint(small only) {
					left:-3px;
				}
			}
			&.btn-right {
				@include breakpoint(small only) {
					right:-3px;
				}
			}
		}
	}
	#arrow-scroll {
		display:none;
		position:absolute;
		left: 50%;
		bottom: 54px;
		margin-left: -25px;
		width: 50px;
		height: 30px;
        z-index:10;
		@include transition(all .1s ease);
		@include opacity(1);
		@include breakpoint(small only) {
			display:none;
		}
		@include breakpoint(large) {
			display:block
		}
		&:hover {
			@include opacity(0.7);
		}
		&:before {
			content:'';
			position:absolute;
			left:-3px;
			top:13px;
			height:4px;
			width:35px;
			background:white;
			@include transform(rotate(45deg));
		}
		&:after {
			position:absolute;
			left:19px;
			top:13px;
			content:'';
			height:4px;
			width:35px;
			background:white;
			@include transform(rotate(-45deg));
		}
	}
	.section-welcome {
		padding:30px 0;
		@include breakpoint(medium) {
			padding:50px 0;
		}
		h2 {
			@include breakpoint(medium) {
				font-size:58px;
				line-height:62px;
			}
		}
		p {
			font-size:14px;
			font-weight:300;
			@include breakpoint(medium) {
				font-size:18px;
			}
		}
	}
	.section-discover, .section-calendar {
		background:$site-gray url(../images/bg-discover.jpg) no-repeat center center;
		padding:30px 0;
		@include background-size(cover);
		@include breakpoint(medium) {
			padding:50px 0;
		}
	}
	.section-calendar {
		.date {
			padding-top:5px;
		}
		.day_of_month {
			line-height:80px;
		}
		.event_details {
			padding-top:10px;
			font-size:15px;
		}
	}
	.num-tile {
		position: relative;
		width: 42px;
		text-align: center;
		display: inline-block;
		font-family: "TradeGothicLTPro-BdCn20";
		font-size: 34px;
		background:$site-gray-light;
		color:$site-gray;
		padding: 2px 0px 0px;
		line-height: 36px;
		letter-spacing: -1px;
	}
	.discover-tile {
		.title, ul {color:white;}
		.title {
			margin-left:8px;
			display:inline-block;
			font-size:28px;
			font-family:$trade-gothic;
			font-weight:normal;
			text-transform:uppercase;
		}
	}
	.line {
		position:relative;
		width:100%;
		overflow:hidden;
		text-align:center;
		font-size:32px;
		color:white;
		margin-bottom:40px;
		font-family:$trade-gothic;
		font-weight:normal;
		text-transform:uppercase;
		@include breakpoint(medium) {
			font-size:58px;
		}
		.inner {
			position:relative;
			display:inline;
			padding:0 13px;
			@include breakpoint(medium) {
				padding:0 25px;
			}
			&:before,{
				content:'';
				position:absolute;
				width:200%;
				height:1px;
				background:white;
				left:100%;
				top:50%;
				margin-top:-2px;
			}
			&:after {
				content: '';
				position: absolute;
				width: 200%;
				height: 1px;
				background: white;
				right: 100%;
				top:50%;
				margin-top:-2px;
			}
		}
}
	.slick-dots {
		margin:0px;
		padding:0px;
		text-align:center;
		li {
			display:inline-block;
			padding:6px 5px 0px;
			&:before {
				content:"\f111";
				font-family:FontAwesome;
				font-size:14px;
				color:$site-gray-light;
			}
			&.slick-active:before {
				color:$site-gray;
			}
		}
		button {display:none}
	}
	.section-slider {
		background:white;
		padding:30px 0;
		@include breakpoint(medium) {
			padding:50px 0;
		}
		#home-feat-slider {
			margin-left: -10px;
			margin-right: -10px;
		}
		.home-feature {
			padding:0 10px;
			img {
				margin:auto
			}
		}
		.slider-wrap {
			position:relative;
			@include breakpoint(medium) {
				padding-left:50px;
				padding-right:50px;
			}
		}
		#slide-left-arw {
			display:none;
			cursor:pointer;
			position:absolute;
			top:50%;
			left:7px;
			font-size:75px;
			color:#d0d1d3;
			line-height: 60px;
			margin-top: -36px;
			@include breakpoint(medium) {
				display:block;
			}
		}
		#slide-right-arw {
			display:none;
			cursor:pointer;
			position:absolute;
			top:50%;
			right:7px;
			font-size:75px;
			color:#d0d1d3;
			line-height: 60px;
			margin-top: -36px;
			@include breakpoint(medium) {
				display:block;
			}
		}
	}
	.alert-bar {
		text-align:center;
		color:white;
		height:34px;
		padding:6px 15px 6px 90px;
		background: #FF5722;
		@include breakpoint(medium) {
			padding:6px 15px;
		}
		p {
			margin:0px;
			text-transform:uppercase;
			font-size:12px;
			overflow:hidden;
			text-overflow:ellipsis;
			height: 100%;
			width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			@include breakpoint(medium) {
				font-size:14px;
			}
		}
		a {color:white;}
	}
	section#section-alerts {
	    position: relative;
	    z-index:10;
	}
	.tag {
		position: absolute;
		left: 0px;
		top: -7px;
		padding: 12px 12px;
		color: white;
		background: #FF5722;
		font-weight:600;
		@include breakpoint(medium) {
			padding: 12px 20px;
		}
	}
	.tag:before {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 7px 0 0 9px;
		border-color: transparent transparent transparent #FF4A11;
		position: absolute;
		right: -9px;
		top: 0px;
	}

	.tag:after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 7px 9px 0 0;
		border-color: #FF4A11 transparent transparent transparent;
		right: -9px;
		bottom: 0px;
	}

	.tag .fill {
		position: absolute;
		right: -10px;
		top: 7px;
		height: 34px;
		background: #FF4A11;
		display: block;
		width: 10px;
	}
}
.section-calendar {
	background-image:url(../images/bg-calendar.jpg);
	@include breakpoint(small only) {
		text-align:center;
	}
	.today-date {
		font-weight:300;
		color:$site-gray;
		font-size:17px;
		margin-bottom:35px;
		@include breakpoint(medium) {
			font-size:23px;
		}
	}
	.day-col {
		position:relative;
		color:$site-gray;
    	@include breakpoint(medium down) {
        	margin-bottom:20px;
    	}
    	@include breakpoint(small only) {
        	margin-bottom:40px;
        	height:auto !important;
    	}
		&:after {
			content:'';
			width:1px;
			height:100%;
			background:white;
			position:absolute;
			top:0px;
			right:20px;
			@include breakpoint(small only) {
                width: 60px;
                height: 1px;
                top: auto;
                bottom: -25px;
                right: auto;
                left: 50%;
                margin-left: -30px;
			}
		}
/*
		&.last {
			@include breakpoint(medium down) {
				display:none;
			}
		}
		&.last:after {
			display:none;
		}
*/
	}
	.btn-gray {
		display:block;
		font-size:22px;
		width:280px;
		text-align:center;
		margin:45px auto 0;
	}
	.date {
		clear:both;
		color:$site-gray;
	}
	.day_of_month {
		font-family:$trade-gothic;
		font-size:68px;
		display:inline-block;
		letter-spacing:-1px;
		padding-right:8px;
		margin:0px;
	}
	.rt-col {
		display:inline-block;
		text-align:left;
	}
	.day_of_week, .month {
		display:block;
		text-transform:uppercase;
		font-family:$trade-gothic;
		font-size:24px;
		font-size: 26px;
		margin: 0px;
		line-height: 30px;
	}
	.event_details {
		padding-right:0px;
		color:$site-gray;
		@include breakpoint(medium) {
			padding-right:18px;
		}
		p {
			color:$site-gray;
			line-height:1.25em;
			margin-bottom:4px;
		}
	}
}

.section-top-image {
	position:relative;
	height:280px;
	margin-bottom:20px;
	background-repeat:no-repeat;
	background-position:center top;
	@include background-size(cover);
	@include breakpoint(medium) {
		margin-bottom:40px;
		height:500px;
	}
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;
		background: #222;
		left: 0px;
		@include opacity(.35);
	}
	.text-wrap {
		position:relative;
		z-index:1;
		width: 100%;
		text-align: center;
		display: table;
		height: 100%;
		vertical-align: middle;
		padding-top: 50px;
		@include breakpoint(medium) {
			padding-top:75px;
		}
	}
	.text-inner {
	    display: table-cell;
	    height: 100%;
	    vertical-align: middle;
	}
	h1 {
		font-size:30px;
		line-height:36px;
		color:white;
		font-weight:700;
		margin-bottom:0px;
		@include breakpoint(medium) {
			font-size:62px;
			line-height:70px;
			margin-bottom:20px;
		}
	}
	h3 {
		color:white;
		font-size:20px;
		text-transform:none;
		font-weight:400;
		letter-spacing:0px;
		@include breakpoint(medium) {
			font-size:30px;
			line-height:36px;
		}
	}
}

// Referral Form
#ReferralForm {
	.btn {
		margin-bottom:15px;
	}
}

// Alum Stories
.AlumniStoriesPage, .TeacherSpotlightPage, .LeadershipTeamPage {
	.alum-item {
		margin-bottom:30px;
		cursor:pointer;
	}
	.alum-item-inner {
		position:relative;
		overflow:hidden;
		&:hover {
			.alum-info {
				@include opacity(1);
			}
		}
	}
	.alum-info {
		position:absolute;
		width:100%;
		height:100%;
		text-align:center;
		padding:0 10px;
		background:rgba(255, 255, 255, 0.65);
		@include opacity(0);
		@include transition(all .2s ease);
		.name {
			font-weight:700;
			font-size:20px;
			margin-bottom:0px;
			color:$site-gray;
			@include breakpoint(medium) {
				font-size:25px;
			}
		}
		.subline {
			font-weight:400;
			font-size:15px;
			color:$site-gray;
			@include breakpoint(medium) {
				font-size:17px;
			}
		}
	}
	.alum-full {
		position:relative;
		margin-bottom:50px;
		display:none;
		&.active {
			display:block;
		}
		h2 {
			font-weight:700;
			margin-bottom:0px;
			font-size:27px;
			@include breakpoint(large) {
				font-size:34px;
			}
		}
		h4 {
			font-weight:700;
			font-size:20px;
			@include breakpoint(large) {
				font-size:24px;
			}
		}
		.col-img {
			width:100%;
			height:100%;
			@include breakpoint(medium) {
				position:absolute;
				width:250px;
				height:250px
			}
			@include breakpoint(large) {
				width:350px;
				height:350px
			}
		}
		.col-info {
			width:100%;
			display:block;
			background:#ebebeb;
			padding-top:20px;
			padding-bottom:20px;
			@include breakpoint(medium) {
				height:250px;
				padding-left:250px;
			}
			@include breakpoint(large) {
				height:350px;
				padding-left:350px;
				padding-top:30px;
				padding-bottom:30px;
			}
			.inner {
				padding: 0px 30px;
				height: 100%;
				overflow-y: scroll;
				-webkit-overflow-scrolling: touch;
				@include breakpoint(medium) {
					padding:0px 25px;
				}
				@include breakpoint(large) {
					padding:0px 40px;
				}
			}
			.content, .content p {
				font-size:15px;
			}
		}
	}
}

// Resources Page
.ResourcesPage {
	#main-content {
		padding-bottom:0px;
	}
	.icon-item {
		width:100%;
		height:235px;
		display:block;
		float:left;
		text-align:center;
		@include breakpoint(medium) {
			width:50%;
			height:275px;
		}
		@include breakpoint(1000px up) {
			width:25%;
			height:275px;
		}
		h3 {
			font-family:$trade-gothic;
			text-transform:uppercase;
			letter-spacing:-1px;
			color:white;
			font-size:25px;
			font-weight:normal;
			margin-top:10px;
			margin-bottom:0px;
			@include breakpoint(medium) {
				font-size:29px;
			}
		}
		img {
			height:80px;
			@include breakpoint(medium) {
				height:95px;
			}
		}
	}
	.item-renweb {
		background:#91a1b4;
	}
	.item-newsletter {
		background:#dbc8a3;
	}
	.item-menu {
		background:#093161;
	}
	.item-calendar {
		background:#c39336;
	}

	.section-menu {
		background:#e0e0e0;
		padding:30px 0;
		@include breakpoint(medium) {
			padding:60px 0;
		}
		.event_details {
			ul {list-style-type:none;margin:0px}
		}
	}
}

// Lunch Menu Page
.LunchMenuPage, .HomePage {
	.section-calendar {
		.today-date, .date, .event_details, span, p {color:white !important;}
	}
}
.LunchMenuPage {
	#main-content {
		padding-bottom:0px;
	}
	.pad {
		padding:30px 0;
		@include breakpoint(medium) {
			padding:60px 0;
		}
	}
	.section-top-image {
		margin-bottom:0px;
	}
	.section-menu {
		background:$site-gray;
		padding:30px 0;
		@include breakpoint(medium) {
			padding:60px 0;
		}
		.event_details {
			ul {list-style-type:none;margin:0px}
		}
		.tg-heading {
			color:white;
		}
	}
	.menu-nav {
		position:relative;
		text-align:center;
		max-width:350px;
		margin:auto;
		#prev, #next {
			position:absolute;
			color:white;
			font-size:38px;
			top:-10px;
			left:0px;
		}
		#next {
			left:inherit;
			left:auto;
			right:0px;
		}
	}
	.tba-menu {
		text-align:center;
		color:white;
	}
}

@import 'pages/photogallery';
@import 'pages/calendar';
@import 'pages/blog';
@import 'pages/staff';

.RequestInformationPage, .ContactPage {
	form .field label {
		display:none
	}
}

// Footer
#footer {
	background:$site-gray-light;
	padding:30px 0;
	@include breakpoint(medium) {
		padding:50px 0;
	}
	h4 {
		font-family:'Open Sans';
		text-transform:none;
		font-weight:600;
		letter-spacing:0px;
		@include breakpoint(small only) {
			text-align:center;
			margin-top:25px;
			padding-top:20px;
			&:before{
				content: '';
				width: 60px;
				height: 1px;
				background: $site-gray;
				display: block;
				margin: auto;
				top: -20px;
				position: relative;
			}
		}
	}
	.logo {
		width:185px;
		margin-bottom:12px;
	}
	p,h5,a {font-family:'Open Sans'; color:$site-gray;}
	p {
		font-size:15px;
		line-height:21px;
		@include breakpoint(medium down) {
			font-size:14px;
			line-height:21px;
		}
	}
	p .fa {
		font-size:22px;
	}
	h5 {margin-bottom:0px}
	.sml {
		font-size:13px;
	}
	.photo-row{
		margin-left: -4px;
		margin-right: -4px;
	}
	.photo-col {
		padding-bottom:10px;
		@include grid-column(4, 10px);
		@include breakpoint(medium) {
			@include grid-column(4, 10px);
		}
	}
	.tweet-content {
		position:relative;
		padding-left:30px;
		padding-bottom:20px;
		&:before {
			content: "\f099";
			font-family: FontAwesome;
			font-size: 21px;
			position: absolute;
			left: 0px;
			color: #00aced;
		}
		em {
			float: right;
			font-size: 14px;
			line-height: 16px;
		}
	}
}

// Additional Print Styles
@media print {
  #header, #mobile-menu, #footer {display:none;}
  .section-top-image {display:none;}
}

input[name=CaptchaToken] {
	display: inline-block !important;
	width: 1px !important;
	position: absolute !important;
	left: 25px !important;
	transform: translateY(45px) !important;
	height: 1px !important;
	opacity: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	border: 0 !important;
}
