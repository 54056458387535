#department-grid #staff-grid {
	display: flex;
	flex-wrap: wrap;

	
	.staff {
		@include breakpoint (medium up) {
			width: 160px;
		}
		@include breakpoint (small down) {
			width: 100%;
		}
		margin: 10px;
		overflow: hidden;

		img, .photo {
			display: inline-block;
			width: 100%;
			background: $dark-gray;
		}

		.photo {
			width: 100%;
			padding-top: 100%;
		}

		.text {
			max-width: 100%;
			word-wrap: break-word;
		}

		.position, .email {
			font-size: 0.8rem;
		}

		.email {
			font-style: italic;
		}
	}
}