// CalendarPage
.CalendarPage {
	.dropdown.button.secondary:after {display:none !important;}
	#main-content {
		padding-top:70px;
		@include breakpoint(medium) {
			padding-top:140px;
		}
	}
}

/* Mobile View */
.cal-event {
	position:relative;
	padding-top:5px;
	padding-bottom:8px;
	border-bottom:1px solid $site-blue; 
	color:$site-blue;
	&:hover {
		color:$site-blue;
	}
	&.first {
		border-top:1px solid $site-blue; 
	}
	.event-title {
		margin-bottom:0px;
		font-size:14px;
		letter-spacing:0px;
	}
	.info {margin-bottom:0px;}
	.fa-chevron-right {
		position: absolute;
		right: 12px;
		top: 8px;
		font-size: 16px;
	}
}

.CalendarPage .cal-tools {
	margin-top:30px;
}
#cat-filter-wrap {
	.dropdown {
		font-size:14px;
		font-weight:normal;
		padding-right:12px;
		&:before {content:none;}
	}
	ul {
		padding:0px;
		a {
			font-size:15px;
			padding:10px;
		}
	}
}
.CalendarPage .cal-nav-wrap {
	width:100%;
	background:$site-gray;
}
.CalendarPage #calendar_nav {
  position:relative;
  text-align:center;
  width:220px;
  margin:0px 0px 0px 80px;
  padding:10px 0 12px;
  @include breakpoint(980px up) {
		margin:0 auto;
		width:300px;
  }
  .active-date {
	  margin:0px;
	  color:white;
	  font-size:38px;
	  font-weight:normal;
	  .year {
		  font-weight:300;
	  }
	  
  }
  .month-heading {
	  font-weight:300;
	  font-size:48px;
  }
}
.CalendarPage #calendar_nav #calendar_prev {
  position:absolute;
  left:-75px;
  top:-5px;
  font-size:58px;
  padding-top:3px;
  padding-right:1px;
  color:$site-gray;

}
.CalendarPage #calendar_nav #calendar_next {
  position:absolute;
  right:-75px;
  top:-5px;
  font-size:58px;
  padding-top:3px;
  padding-right:1px;
  color:$site-gray;
}
.calendar_options {
  position:relative;
  top:-67px;
  z-index:10;
}
.calendar_options .calendar_category_dropdown {
  position:absolute;
  right:50px;
  width:170px;
  overflow:hidden;
  background:#ffffff;
  ul {
	  border:2px solid $site-gray-light;
	  border-top:none;
  }
}
.calendar_options .calendar_category_dropdown .calendar_dropdown_arrow {
	height: 38px;
	line-height: 30px;
	padding: 3px 0 0 12px;
	font-size: 15px;
	background:$site-gray;
	color:white;
	.fa {
		position: absolute;
		font-size: 19px;
		right: 10px;
		top: 9px;
	}
}
.calendar_options .calendar_category_dropdown ul { display:none; }
.calendar_options .calendar_category_dropdown:hover { overflow:visible; }
.calendar_options .calendar_category_dropdown:hover ul {
  display:block;
  padding:0;
  margin:0;
  list-style:none;
}
.calendar_options .calendar_category_dropdown:hover ul li { list-style:none; }
.calendar_options .calendar_category_dropdown:hover ul li a {
  display:block;
  padding:2px 5px;
  font:12px/16px 'OpenSansRegular', Arial, sans-serif;
}
.calendar_options .calendar_category_dropdown:hover ul li a:hover { background:#eeeeee; }
.calendar_options .calendar_months {
  height:40px;
  width:40px;
  position:absolute;
  right:0;
  top:-3px;
  padding-top:40px;
  .fa {
	  position:absolute;
	  top:5px;
	  right:0px;
	  font-size:34px;
	  color:$site-gray;
  }
}
.calendar_options .calendar_months .calendar_months_hidden { display:none; }
.calendar_options .calendar_months:hover { width:100px; }
.calendar_options .calendar_months:hover .calendar_months_hidden {
  display:block;
  background:#ffffff;
  border:2px solid $site-gray-light;
}
.calendar_options .calendar_months:hover .calendar_months_hidden ul {
  padding:0;
  margin:0;
  list-style:none;
}
.calendar_options .calendar_months:hover .calendar_months_hidden ul li { list-style:none; }
.calendar_options .calendar_months:hover .calendar_months_hidden ul li a {
  display:block;
  padding:2px 5px;
  font:12px/16px 'OpenSansRegular', Arial, sans-serif;
}
.calendar_options .calendar_months:hover .calendar_months_hidden ul li a:hover { background:#eeeeee; }
.CalendarPage table#Calendar {
	table-layout:fixed;
	border-collapse:collapse;
	@include breakpoint(medium){
		margin-bottom:230px;
	}
}
.CalendarPage table#Calendar tr { background:#ffffff; border-left: 1px solid #c5c5c5; border-right: 1px solid #c5c5c5; }
.CalendarPage table#Calendar th {
  padding:5px;
  background:$site-blue;
  color:white;
  text-align:center;
  font-weight:600;
}
.CalendarPage table#Calendar td {
  border:1px solid #c5c5c5;
  height:105px;
  padding:8px 5px 5px;
  background:white;
  vertical-align:top;
}
.CalendarPage table#Calendar td.today { background:#eaeaea; }
.CalendarPage table#Calendar td.lastmonth {
	@include opacity(.6); 
	&:hover {
		@include opacity(1); 
	}
}
/* .CalendarPage table#Calendar td.lastmonth:hover { opacity:1; } */
.CalendarPage table#Calendar td .Date {
  color:#969595;
  padding:0 5px 2px;
}
.CalendarPage table#Calendar td .Event {
  position:relative;
  padding-left:16px;
  margin-bottom:3px;
  font-size:12px;
  a {color:$site-gray;}
}
.CalendarPage table#Calendar td .Event .bullet {
  position:absolute;
  left:3px;
  top:4px;
  display:block;
  width:6px;
  height:6px;
  margin:1px 1px;
  -webkit-border-radius:12px;
  -webkit-border-radius:12px;
  -moz-border-radius:12px;
  -moz-border-radius:12px;
  border-radius:12px;
  border-radius:12px;
  background:$site-blue;
}
.CalendarPage table#Calendar td .Event span.Category_1 { background:#a12231; }
.CalendarPage table#Calendar td .Event span.Category_2 { background:#126a1f; }
.CalendarPage table#Calendar td .Event span.Category_3 { background:#d59f0e; }
.CalendarPage table#Calendar td .Event span.Category_4 { background:#002c61; }
.CalendarPage table#Calendar td .Event span.Category_5 { background:#323232; }
.CalendarPage table#Calendar td .Event .event_preview {
  display:none;
  width:300px;
  background:white;
  position:absolute;
  z-index:1000;
  top:-30px;
  left:120px;
  padding:15px 25px;
  border:2px solid $site-gray;
}
.CalendarPage table#Calendar .col-5, .CalendarPage table#Calendar .col-6, .CalendarPage table#Calendar .col-0 {
	.Event .event_preview {
		left:auto;
		left:initial;
		right:130px;
		.arrow {
			left:auto;
			left:initial;
			right:-14px;
			border-width: 13px 0 13px 14px;
			border-color: transparent transparent transparent #fff;
			&:before {
				content:'';
				width: 0px;
				height: 0px;
				border-style: solid;
				border-width: 16px 0 16px 17px;
				border-color: transparent transparent transparent $site-gray;
				position:absolute;
				left:-14px;
				top:-16px;
				z-index:-1;
			}
		}
	}
}
.CalendarPage table#Calendar td .Event .event_preview strong { font:13px/22px 'OpenSansBold', Arial, sans-serif; }
.CalendarPage table#Calendar td .Event .event_preview .event_title { font-size:16px; color:$site-gray; }
.CalendarPage table#Calendar td .Event .event_preview .event_loc, .CalendarPage table#Calendar td .Event .event_preview .event_time { color:white; }
.CalendarPage table#Calendar td .Event .event_preview .arrow {
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 13px 14px 13px 0;
	border-color: transparent #fff transparent transparent;
	position:absolute;
	left:-14px;
	top:23px;
	&:before {
		content:'';
		width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 16px 17px 16px 0;
		border-color: transparent $site-gray transparent transparent;
		position:absolute;
		left:-3px;
		top:-16px;
		z-index:-1;
	}
}
.CalendarPage table#Calendar td .Event .event_preview p { margin:8px 0; color:white;}
.CalendarPage table#Calendar td .Event:hover { cursor:pointer; }
.CalendarPage table#Calendar td .Event:hover .event_preview { display:block; }
/* Mobile View */
.mobile-event-list {
	.calendar_options {
		position:absolute;
		left:50%;
		top:0px;
		margin-left:-55px;
	}
	.calendar_category_dropdown {
		width:110px;
		right:auto;
		right:inherit;
	}
	.calendar_dropdown_arrow {
		height:41px !important;
		font-size:13px !important;
	}
	.day {
		background:$site-gray;
		color:white;
		text-transform:uppercase;
		font-size:13px;
		padding:2px 12px;
		display: block;
		clear: both;
		width: 100%;
	}
	.no-event {
		margin:0px;
	}
}
.week-nav {
	position:relative;
	height:42px;
	.text-center {
		@media (max-width:600px) {
			font-size:17px;
			padding-top:6px;
		}
	}
}
.prev-week, .next-week {
	height:41px;
	position:absolute;
	font-size:12px;
	background:$site-blue;
	color:white;
	top:0px;
	padding:11px 12px;
	&:hover, &:focus {color:white}
	.fa {
		font-size:20px;
		line-height:15px;
		position:relative;
		top:2px;
	}
}
.prev-week {
	left:0px;
	padding-left:10px;
}
.next-week {
	right:0px;
	padding-right:10px;
}

