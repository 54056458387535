// Blog
.BlogHolder {

	.blog_summary {
		border-bottom:1px solid #efefef;
		padding-bottom:20px;
		margin-bottom:35px;
		.postTitle {
			letter-spacing: -1px;
			font-family:$trade-gothic;
			color:$site-gray;
			text-transform:uppercase;
			font-weight:normal;
			font-size:32px;
			line-height:35px;
			margin-bottom:0px;
			@include breakpoint(medium) {
				font-size:47px;
				line-height:50px;
			};
			a {color: $site-gray}
		}
		.meta {
			color:$site-gray;
			font-size: 23px;
			text-transform: uppercase;
			font-weight: normal;
			letter-spacing: -1px;
			font-family:$trade-gothic;
			margin-bottom:6px;
		}
	}
	.content-summary {
		font-weight:300;
	}
	
	.btn-readmore {
		color:$site-orange;
		font-size: 23px;
		text-transform: uppercase;
		font-weight: normal;
		letter-spacing: -1px;
		font-family:$trade-gothic;
	}
	.pagination {
		@extend .text-center;
		margin-top:50px;
	}
	.pagi-older, .pagi-newer {
		float:right;
		color:$site-gray;
		font-size: 23px;
		text-transform: uppercase;
		font-weight: normal;
		letter-spacing: -1px;
		font-family:$trade-gothic;
		.fa {
			font-size:18px;
			position:relative;
			top:-2px;
			margin:0px 2px;
		}
	}
	.pagi-newer {
		float:left;
	}

}

.BlogEntry {
	#header  {
		border:1px solid #efefef;
	}
	.breadcrumbs {
		margin-top:20px;
		font-size:13px;
		color:$site-gray;
		text-transform:uppercase;
		margin-bottom:20px;
		@include breakpoint(medium) {
			margin-bottom:35px;
		}
		a {color:$site-blue;}
	}
	.meta {
		color:$site-gray;
		font-size: 23px;
		text-transform: uppercase;
		font-weight: normal;
		letter-spacing: -1px;
		font-family:$trade-gothic;
		margin-bottom:6px;
	}
	#main-content {
		padding-top:70px;
		@include breakpoint(medium) {
			padding-top:100px;
		}
	}
	.page-title {
		letter-spacing: -1px;
		font-family:$trade-gothic;
		color:$site-gray;
		text-transform:uppercase;
		font-weight:normal;
		font-size:32px;
		line-height:35px;
		margin-bottom:0px;
		border-bottom:1px solid #dedede;
		margin-bottom:8px;
		@include breakpoint(medium) {
			font-size:47px;
			line-height:50px;
		};

	}
	.share-col {
		position:relative;
		margin-bottom:10px;
	}
	#ShareButtons {
		height:30px;
		margin-bottom:5px;
		position: absolute;
		right: 15px;
		.st_twitter_hcount {
			height:23px;
			span {height:23px;}
		}
	}

}

// Sidebar
#SideBar {
	.sidebar-inner {
		border:1px solid #dfdedc;
	}
	.archive-list {
		list-style-type:none;
		border-bottom: 1px solid #dfdedc;
		margin-left: 0px;
		padding: 15px;
		margin-bottom:0px;
		&.last {
			border:none
		}
		ul {
			margin:0px;
		}
	}
	.archive-post {
		color:#777;
		font-style:italic;
		font-size:14px;
		line-height:19px;
		display:block;
		margin-bottom:8px;
	}
	.month-name {
		color:$site-gray;
		font-weight:600;
		letter-spacing:-1px;
	}
	.sidebar-heading {
		background: #e8e7ec;
		font-family: "TradeGothicLTPro-BdCn20";
		font-size: 25px;
		color: #434244;
		text-transform: uppercase;
		font-weight: normal;
		padding: 5px 13px 3px;
		margin-bottom:0px;
	}
	ul.vertical.menu .has-submenu {
	    border-bottom: 1px solid #fff;
	    > a {
		    background:#efefef;
		    padding-left:10px !important;
	    }
	    .fa {font-size:14px;}
	}
	ul.vertical.menu .has-submenu a {
	    padding-left: 2px;
	    font-size: 15px;
	}
	.is-submenu-item a {
		font-size:14px;
	}

}