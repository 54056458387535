@mixin reset-styles(){
	margin: 0;
	padding: 0;
}

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: $image;
    background-size: $width $height;
  }
}



@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}



/*
 * Border Radius
 */

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;

	-webkit-border-top-left-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	-webkit-border-bottom-right-radius: $radius;
	
	border-radius: $radius;
	background-clip: padding-box;  /* stops bg color from leaking outside the border: */
	
	img {
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
		transform: none;
	}
}

// Single side border-radius

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}




@mixin blur($blur){
	-webkit-filter: blur($blur);
	-moz-filter: blur($blur);
	-o-filter: blur($blur);
	-ms-filter: blur($blur);

	filter: url(/themes/praisecharts/blur.svg#blur);
}

@mixin background-size($size){
	background-size:$size;
	-webkit-background-size: $size;
	-moz-background-size: $size;
	-o-background-size: $size;
	-ms-background-size: $size;
}



@mixin box-shadow($left:0, $top:2px, $blur:2px, $color:#000, $inset:"", $spread: 0px, $enabled: true) {

	
    @if ($enabled == true) {
      -webkit-box-shadow:$left $top $blur $spread $color #{$inset};
      -moz-box-shadow:$left $top $blur $spread $color #{$inset};
      box-shadow:$left $top $blur $spread $color #{$inset};
    }

    @if ($enabled == false) {
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
   }
}

/*
		-webkit-box-shadow: none;
		-moz-box-shadow: 0 0 2px 0px #fff inset;
		box-shadow: 0 0 2px 0px #fff inset;		
*/




@mixin cssTriangle($triangleSize, $triangleColor, $triangleDirection, $inset: solid) {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: $inset $triangleSize;
    @if ($triangleDirection == top) {
      border-color: $triangleColor transparent transparent transparent;
    }
    @if ($triangleDirection == bottom) {
      border-color: transparent transparent $triangleColor transparent;
    }
    @if ($triangleDirection == left) {
      border-color: transparent transparent transparent $triangleColor;
    }
    @if ($triangleDirection == right) {
      border-color: transparent $triangleColor transparent transparent;
    }
    // .example { @include css-triangle(5px, #fff, bottom); }
}

// Opacity
@mixin opacity($opacity) {
	// Include mathsass for extended IE support
	//$opacity-ie: $opacity * 100;
	//filter: alpha(opacity=$opacity-ie);
	opacity:$opacity;
}
// Horizontal Gradient
@mixin horizontal-gradient($from, $to, $stop: 100%) {
  /* fallback/image non-cover color */
  background-color: $from;

  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);

  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));

  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);

  /* Opera 11.10+ */
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);

  /* IE10+ */
  background: -ms-linear-gradient(left, $from 0%, $to $stop);

  /* Standard */
  background: linear-gradient(to right, $from 0%, $to $stop);

  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}

// Transition
@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
@mixin bg-layer($color,$opacity) {
	&:after {
		content:'';
		position:absolute;
		z-index:-1;
		top:0px;
		left:0px;
		height:100%;
		width:100%;
		background:$color;
		@include transition(all .2s ease);
		@include opacity($opacity);
	}
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before{
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}