@font-face {font-family: 'TradeGothicLTPro-BdCn20';src: url('../fonts/2AF141_0_0.eot');src: url('../fonts/2AF141_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2AF141_0_0.woff') format('woff'),url('../fonts/2AF141_0_0.ttf') format('truetype');}


@font-face {
    font-family: 'OpenSansLight';
    src: url('../fonts/open-sans/OpenSans-Light-webfont.eot');
    src: url('../fonts/open-sans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans/OpenSans-Light-webfont.woff') format('woff'),
         url('../fonts/open-sans/OpenSans-Light-webfont.ttf') format('truetype'),
         url('../fonts/open-sans/OpenSans-Light-webfont.svg#OpenSansLight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansLightItalic';
    src: url('../fonts/open-sans/OpenSans-LightItalic-webfont.eot');
    src: url('../fonts/open-sans/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans/OpenSans-LightItalic-webfont.woff') format('woff'),
         url('../fonts/open-sans/OpenSans-LightItalic-webfont.ttf') format('truetype'),
         url('../fonts/open-sans/OpenSans-LightItalic-webfont.svg#OpenSansLightItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/open-sans/OpenSans-Regular-webfont.eot');
    src: url('../fonts/open-sans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans/OpenSans-Regular-webfont.woff') format('woff'),
         url('../fonts/open-sans/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/open-sans/OpenSans-Regular-webfont.svg#OpenSansRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansItalic';
    src: url('../fonts/open-sans/OpenSans-Italic-webfont.eot');
    src: url('../fonts/open-sans/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans/OpenSans-Italic-webfont.woff') format('woff'),
         url('../fonts/open-sans/OpenSans-Italic-webfont.ttf') format('truetype'),
         url('../fonts/open-sans/OpenSans-Italic-webfont.svg#OpenSansItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansSemibold';
    src: url('../fonts/open-sans/OpenSans-Semibold-webfont.eot');
    src: url('../fonts/open-sans/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans/OpenSans-Semibold-webfont.woff') format('woff'),
         url('../fonts/open-sans/OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('../fonts/open-sans/OpenSans-Semibold-webfont.svg#OpenSansSemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansSemiboldItalic';
    src: url('../fonts/open-sans/OpenSans-SemiboldItalic-webfont.eot');
    src: url('../fonts/open-sans/OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans/OpenSans-SemiboldItalic-webfont.woff') format('woff'),
         url('../fonts/open-sans/OpenSans-SemiboldItalic-webfont.ttf') format('truetype'),
         url('../fonts/open-sans/OpenSans-SemiboldItalic-webfont.svg#OpenSansSemiboldItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansBold';
    src: url('../fonts/open-sans/OpenSans-Bold-webfont.eot');
    src: url('../fonts/open-sans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans/OpenSans-Bold-webfont.woff') format('woff'),
         url('../fonts/open-sans/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('../fonts/open-sans/OpenSans-Bold-webfont.svg#OpenSansBold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansBoldItalic';
    src: url('../fonts/open-sans/OpenSans-BoldItalic-webfont.eot');
    src: url('../fonts/open-sans/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('../fonts/open-sans/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
         url('../fonts/open-sans/OpenSans-BoldItalic-webfont.svg#OpenSansBoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansExtrabold';
    src: url('../fonts/open-sans/OpenSans-ExtraBold-webfont.eot');
    src: url('../fonts/open-sans/OpenSans-ExtraBold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans/OpenSans-ExtraBold-webfont.woff') format('woff'),
         url('../fonts/open-sans/OpenSans-ExtraBold-webfont.ttf') format('truetype'),
         url('../fonts/open-sans/OpenSans-ExtraBold-webfont.svg#OpenSansExtrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansExtraboldItalic';
    src: url('../fonts/open-sans/OpenSans-ExtraBoldItalic-webfont.eot');
    src: url('../fonts/open-sans/OpenSans-ExtraBoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans/OpenSans-ExtraBoldItalic-webfont.woff') format('woff'),
         url('../fonts/open-sans/OpenSans-ExtraBoldItalic-webfont.ttf') format('truetype'),
         url('../fonts/open-sans/OpenSans-ExtraBoldItalic-webfont.svg#OpenSansExtraboldItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}